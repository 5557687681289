import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer, ResponsiveColRow1000 } from '../components/ui/Div'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow, shuffle } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import telephones from '../../constants/telephones'
import searches from '../../constants/searches'
import useCases from '../../constants/useCases'
import PopularNumbers from '../components/PopularNumbers'
import CountryNumbers from '../components/CountryNumbers'

const HeroContainer = styled(Flex)`
  position: relative;
  margin-bottom: 200px;
  background: rgb(2, 119, 181);
  background: linear-gradient(180deg, rgba(2, 119, 181, 1) 0%, rgba(1, 87, 133, 1) 100%);
  min-height: 600px;
  border-radius: 32px;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 100px;
  align-items: center;
  -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  @media (max-width: 700px) {
    min-height: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 60px;
    padding-bottom: 150px;
    height: auto;
  }
`

const Container = styled(Flex)`
  background-color: white;
  padding: 24px;
  flex-direction: column;
  border-radius: 24px;
`

const PagePadding = styled(Flex)`
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="60px" height="60px" />
}

const IndexPage = () => {
  let popularTelephones = []
  Object.keys(telephones).forEach((country) => {
    popularTelephones = popularTelephones.concat(
      telephones[country].map((tel) => {
        return {
          telephone: tel,
          country
        }
      })
    )
  })
  popularTelephones = popularTelephones.slice(0, 20)
  return (
    <Layout bg="#F6F8F7">
      <Helmet
        title={`Receive SMS Online | 100+ Numbers, 20+ Countries | SMS ANDA`}
        htmlAttributes={{
          lang: 'en'
        }}
        description={`The internet's most extensive collection of temporary phone numbers | Receive SMS online for free with no ads or registration | 100+ Numbers, 20+ Countries.`}
        url="https://sms-anda.com/"
      >
        <html lang="en" />
      </Helmet>
      <Header />

      <PagePadding alignItems="center">
        <HeroContainer maxWidth="1200px">
          <H1 textAlign="center" fontSize="75px" lineHeight="80px" fontSizem="40px" lineHeightm="44px" fontWeight="700" color="white" mb="32px">
            Receive SMS Online
            <Span
              textAlign="center"
              display="block"
              lineHeight="80px"
              fontSize="50px"
              fontSizem="40px"
              lineHeightm="44px"
              fontWeight="700"
              color="rgb(204, 237, 255)"
            >
              100+ Numbers, 20+ Countries
            </Span>
          </H1>
          <H2 textAlign="center" color="white" lineHeight="40px" fontSizem="20px" lineHeightm="24px">
            The internet's most extensive collection of temporary phone numbers. Receive SMS online for free with no ads or registration.
          </H2>
        </HeroContainer>

        <ResponsiveColRow1000 zIndex={1} px="40px" pxm="0px" mt="-300px" mb="100px" justifyContent="space-between" maxWidth="1200px">
          <Flex flexDirection="column" mr="48px">
            <CountryNumbers />
            <Flex mb="60px" flexDirection="column">
              <H3 mb="16px">Why receive SMS online with temporary numbers?</H3>
              <Text>
                No longer give your personal details to every website that requires an account, use our numbers to pass through account verification
                while ensuring you dont receive any marketing messages. Our telephone numbers are also ideal for opening multiple accounts with online
                services that require one time passwords, e.g. using referral codes. You can also open accounts in other locations to your own, for
                example gaining access to an international netflix accounts. Don't trust a website? Sign up with our numbers and keep your details
                safe.
              </Text>
            </Flex>
            <PopularNumbers />
            <Flex mb="48px" flexDirection="column">
              <H3 mb="16px">Why use our SMS numbers?</H3>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">Protect your privacy</H3>
                <Text mb="16px">
                  Avoid unwanted call from telemarketers, spam callers and even bots by using our numbers to sign up to online services. Your real
                  number will never be exposed. You can also prevent harrassment and increase your safety when using online marketplaces (such as
                  ebay, craiglists etc) by not providing your personal number.
                </Text>
              </Flex>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">Testing</H3>
                <Text mb="16px">
                  Test your latest product with our free numbers, perfect for software engineers and customer operations. Receiving sms is completely
                  free and our numbers are online 24/7 ensuring you can reliably test.
                </Text>
              </Flex>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">International Access</H3>
                <Text mb="16px">
                  Many online services block international signups, circumvent these restrictions by using one of our US or UK numbers. If you want to
                  watch content restricted to another country (e.g. netflix or sports), you can unlock using our numbers and a proxy to signup.
                </Text>
              </Flex>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">Travel</H3>
                <Text mb="16px">
                  Using your home country phone number can be very expensive abroad. Use one of our temporary local numbers to save yourself money
                  while making bookings or reservations on holiday.
                </Text>{' '}
              </Flex>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">Free</H3>
                <Text mb="16px">
                  Our services is 100% free and will never cost our users to receive sms online. Better still we have no adverts to distract from your
                  experience!
                </Text>{' '}
              </Flex>
              <Flex mb="32px" flexDirection="column">
                <H3 mb="16px">Largest Database</H3>
                <Text mb="16px">
                  With over 20 countries and 100 numbers were confident we have the largest selection available online to receive sms online. Let us
                  know if there are any further countries you would like us to add!
                </Text>{' '}
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="300px" minWidth="300px" widthm={'100%'}>
            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Our numbers can all be used for:
              </Text>
              <Flex flexWrap="wrap">
                {searches.map((search) => {
                  return (
                    <a href={`/${stringToUrl(search)}/`}>
                      <PillButton mb="16px" mr="16px" label={search} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <a href={`/united-states/${telephones['united-states'][1]}`}>
              <Container flexWrap="wrap" mb="60px">
                <Text mb="16px" fontSize="18px" fontWeight="500">
                  Latest Phone Number
                </Text>
                <Flex position="relative">
                  <Flex alignItems="center">
                    <Flag iso2={'US'} />
                    <Flex flexDirection="column" ml="16px">
                      <Text width="100%" fontSize="22px" textAlign="left" mr={2} mb="8px">
                        +{telephones['united-states'][0]}
                      </Text>
                      <Text>
                        Created: <Span color={colors.fontLight}>1 day ago</Span>
                      </Text>
                    </Flex>
                    <OnlineIcon />
                  </Flex>
                </Flex>
              </Container>
            </a>

            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Services our numbers work for:
              </Text>
              <Flex flexWrap="wrap">
                {useCases.map((useCase) => {
                  return (
                    <a href={`/${stringToUrl(useCase)}/`}>
                      <PillButton mb="16px" mr="16px" label={useCase} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <Container flexWrap="wrap">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Countries
              </Text>
              <Flex flexWrap="wrap">
                {Object.keys(telephones).map((country) => {
                  return (
                    <a href={`/${country}/`}>
                      <PillButton mb="16px" mr="16px" label={urlToString(country)} />
                    </a>
                  )
                })}
              </Flex>
            </Container>
          </Flex>
        </ResponsiveColRow1000>
      </PagePadding>
      <Footer iso2={'en'} />
    </Layout>
  )
}

export default IndexPage
